.tableToolbar {
    margin: 15px 0 0px 0;
}

.tableToolbar .MuiFormControlLabel-root {
    margin: 0;
    flex-direction: row-reverse;
}

.tableToolbar .MuiSwitch-colorSecondary.Mui-checked {
    color: var(--primColor);
}

.tableToolbar .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: var(--primColor);
}

.tableimage {
    width: 80px;
    height: 80px;
    border-radius: 15px;
    object-fit: cover;
}

.tableimage.small {
    max-width: 40px;
    max-height: 40px;
    border-radius: 10px;
}

.MuiIconButton-colorSecondary,
.MuiCheckbox-colorSecondary.Mui-checked {
    color: var(--primColor) !important;
}

.MuiTableContainer-root::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
    height: 10px;
}
.MuiTableContainer-root::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.MuiTableContainer-root:hover::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
}

.MuiTableContainer-root {
    max-height: 70vh !important;
    margin-bottom: 20px;
}

.MuiTable-root .MuiTableRow-root .MuiTableCell-root.ws_noWrap {
    white-space: nowrap;
}

.MuiTable-root .MuiTableRow-root .MuiTableCell-root button {
    min-width: max-content;
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.MuiTable-root .MuiTableRow-root .MuiTableCell-root button .MuiButton-label {
    color: #9f9f9f;
}

.MuiTable-root .MuiTableRow-root .MuiTableCell-root button:hover .MuiButton-label {
    color: var(--primColor);
}

.MuiTable-root .MuiTableRow-root .MuiTableCell-root:last-child {
    text-align: center;
}
