.addUserModal .catgItems {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
}

.addUserModal .catgItems .customCheckbox {
    width: 20%;
}

.addUserModal .addMore .material-icons,
.addUserModal .removeItem .material-icons {
    padding-right: 10px;
}

.addUserModal .addMore .MuiButton-label {
    color: var(--primColor);
}

.addUserModal .removeItem .MuiButton-label {
    color: #f45d4a;
}

@media (max-width: 500px) {
    .addUserModal .catgItems .customCheckbox {
        width: 100%;
    }
}
