body {
    margin: 0;
    font-family: var(--fontFamily), 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

div:focus,
button:focus {
    outline: 0;
}

.MuiButton-label {
    font-family: var(--fontFamily), 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.MuiBadge-badge {
    background-color: var(--primColor) !important;
}

.customInput,
.customTextArea,
.customSelect,
.MuiFormControl-root.MuiTextField-root.customDatepicker {
    margin-bottom: 25px;
    width: 100%;
    text-align: left;
}

.customInput .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl,
.customSelect .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl,
.MuiOutlinedInput-adornedEnd {
    height: 50px;
}

.customInput .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutlin,
.customTextArea .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutlin,
.customSelect .MuiSelect-root.MuiSelect-select.Mui-focused {
    border-color: var(--secColor);
}

.customInput .MuiFormLabel-root.Mui-focused,
.customTextArea .MuiFormLabel-root.Mui-focused,
.customSelect .MuiFormLabel-root.Mui-focused {
    color: var(--secColor);
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--secColor) !important;
    border-width: 1px !important;
}
.customInput .MuiFormControl-root.MuiTextField-root,
.customTextArea .MuiFormControl-root.MuiTextField-root,
.customSelect .MuiFormControl-root.MuiTextField-root {
    width: 100%;
    margin: 0;
}

.MuiInputBase-root,
.MuiTypography-body1 {
    font-family: var(--fontFamily) !important;
}

.primButton .material-icons {
    padding-right: 10px;
}

.layout > .header {
    background: #000;
}

.layout > .header .MuiButtonBase-root {
    color: #ccc;
}

/* table {
  border: 1px solid #dee2e6;
  table-layout: fixed;
}

table.table thead th {
  font-weight: 500;
  border: none;
  background: #f7f7f7;
  border-bottom: 1px;
  text-align: left;
  vertical-align: middle;
}

table.table tbody th,
table.table tbody td {
  text-align: left;
  vertical-align: middle;
}

table.table thead th.tableAction,
table.table tbody th.tableAction,
table.table tbody td.tableAction {
  text-align: center;
}

table.table thead th.tableCheck,
table.table tbody th.tableCheck,
table.table tbody td.tableCheck {
  width: 75px;
}

table.table .MuiFormControlLabel-root {
  margin: 0;
}

.table td,
.table th {
  padding: 5px;
} */

.noImage.small {
    width: 60px;
    height: 60px;
    object-fit: cover;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
    margin-top: 15px;
    cursor: pointer;
}

.dropzone h4 {
    margin: 0;
    font-size: 18px;
    color: #929292;
    text-align: center;
    font-weight: 400;
}

.dropzone:focus {
    border-color: var(--primColor);
}

.dropzone.disabled {
    opacity: 0.6;
}

.thumb .thumbInner img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.actionWrapper {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
}

.actionWrapper > button:first-child {
    margin-right: 30px;
}

.customDialog .MuiDialogTitle-root h2 {
    color: #232323;
}

.customDialog h5 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
}

.lotassignCnf .customInput,
.lotassignCnf .customSelect {
    margin-bottom: 15px;
    width: 400px;
    max-width: 100%;
}

.fspBody {
    max-width: 1250px;
    width: 100%;
    margin: 0 auto;
}

.fspBody .actionButton {
    margin-top: 45px;
}

.fspBody .actionButton .secButton {
    margin-right: 20px;
}

.fspBody .actionButton button {
    width: max-content;
}
.fspBody .actionButton button {
    min-width: 200px;
}

.fspBody > .dashTitle {
    margin-top: 0;
}

.scheduleError {
    color: #f44336;
    font-size: 0.75rem;
    font-family: Roboto;
}

.thumbsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
}

.thumbInner {
    display: flex;
    min-width: 0;
    overflow: hidden;
}

.img {
    display: block;
    width: auto;
    height: 10%;
}

.MuiSwitch-colorPrimary.Mui-checked {
    color: var(--primColor) !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: var(--primColor) !important;
}

.MuiAppBar-colorPrimary {
    background-color: var(--sidePanelBg) !important;
}

.MuiTabs-indicator {
    background-color: var(--accentColor) !important;
    height: 3px;
}

.MuiButtonBase-root.MuiTab-root.Mui-selected .MuiTab-wrapper {
    color: var(--accentColor);
}

.empPermissions > .customCheckbox {
    width: 20%;
}

.cursorDecoy {
    cursor: pointer;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
    font-size: 12px;
    background: #2b2b2be0;
}

.primButton .MuiCircularProgress-colorPrimary {
    color: #fff;
    padding: 5px;
}

.fspLoader {
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.listingInfo {
    margin-bottom: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #199de51a;
    padding: 15px;
    border-radius: 3px;
    font-weight: 500;
    color: var(--primColor);
}

.listingInfo .material-icons {
    color: var(--primColor);
    padding-right: 15px;
}

.listingInfo .liDivider {
    padding: 0 15px;
    display: inline-block;
}

.listingInfo h6 {
    margin: 0;
    font-size: 15px;
}

.listingInfo h6 span {
    padding-left: 10px;
}
.regDivider {
    margin-bottom: 30px;
}

.header .headLogo img {
    width: 110px !important;
    object-fit: contain;
}

.layout .MuiPaper-root.MuiDrawer-paper .subLinks .MuiListItem-button a.active .MuiTypography-root {
    font-weight: 600;
}

@media (max-width: 1024px) {
    .empPermissions .customCheckbox {
        width: 25%;
    }
}

@media (max-width: 768px) {
    .empPermissions .customCheckbox {
        width: 50%;
    }

    .empPermissions .MuiFormGroup-root .MuiFormControlLabel-root {
        margin: 0;
    }
}

.MuiDivider-root {
    background-color: rgb(138 138 138 / 55%) !important;
}
@media (max-width: 500px) {
    .fspBody .actionButton {
        flex-direction: column-reverse;
    }
    .fspBody .actionButton .secButton {
        margin-right: 0;
        width: 100%;
        margin-top: 10px;
    }

    .fspBody .actionButton .primButton {
        width: 100%;
    }

    .fspBody .actionButton .primButton button,
    .fspBody .actionButton .secButton button {
        width: 100%;
    }
}

@media (max-width: 445px) {
    .empPermissions .customCheckbox {
        width: 100%;
    }
    .thumbsContainer .thumb {
        margin-bottom: 10px;
        margin-right: 10px;
    }
    .thumb {
        width: 100px !important;
        height: 100px !important;
    }
}

@media print {
    .MuiDialog-paperFullScreen header,
    .noPrint {
        display: none !important;
    }
    .productInvoice {
        height: 100% !important;
        width: 100vw !important;
        margin-top: 0 !important;
        min-height: 100vh;
    }
    .productInvoice .invoiceView {
        height: auto !important;
    }
}
