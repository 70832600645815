.header {
    height: 90px;
    box-shadow: 0px 5px 25px #e0e0e0;
    padding: 15px 0;
    display: flex;
    align-items: center;
    width: 100%;
}
.header .headLogo img {
    width: 100px;
}

.header button span.material-icons {
    padding-left: 10px;
}
