.dashboard {
    margin-top: 30px;
}

.dashboard .dashboardCard {
    margin-top: 30px;
    display: grid;
    grid-gap: 15px;
    gap: 15px;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}

.dashboard.listing.reportListing .customInput {
    margin-bottom: 0;
}

.dashboard .actionButton {
    margin: 45px 0 30px 0;
    text-align: left;
}

.dashboard .actionButton button {
    min-width: 200px;
}

.dashboard .dashTableAct h6 {
    margin: 0;
    padding-right: 20px;
}

.dashboard .dashTableAct button {
    color: var(--primColor);
    border-color: var(--primColor);
}

.dashboard .resultActions h6 {
    font-size: 16px;
    font-weight: 600;
    padding-right: 30px;
    margin: 0;
}

.dashboard .resultActions .customInput {
    margin: 0;
    width: 100px;
}

.dashTitle {
    text-align: left;
    font-size: 18px;
    text-transform: uppercase;
    margin: 30px 0 15px 0;
}

.dashboard .actionButton button {
    width: max-content;
}

.dashboard .infoCard {
    width: 100%;
    height: 160px;
    border-radius: 5px;
    background: #22d587;
    transition: 250ms background ease-in-out;
}

.dashboard .infoCard:hover {
    opacity: 0.9;
}

.dashboard .infoCard button {
    width: 100%;
    height: 100%;
    text-align: right;
    /* background: #747473; */
    background: inherit;
    padding: 20px;
}

.dashboard .infoCard button .MuiButton-label {
    flex-wrap: wrap;
}

.dashboard .infoCard button .MuiButton-label h3 {
    width: 100%;
    text-align: right;
    font-size: 35px;
    font-size: 45px;
    font-weight: 300;
}

.dashboard .infoCard button .MuiButton-label h6 {
    width: 100%;
    text-align: right;
}

/* AUCTION LOTS */
.dashboard.auctionLots .addAuction .primButton {
    width: 220px;
}

.dashboard.auctionLots .custom-modal .modal-dialog.modal-lg {
    max-width: 600px;
}

/* REPORT MANAGEMENT */
.dashboard.reportManagement .dashSubtitle {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    margin-top: 45px;
}

.dashboard.reportManagement .reportAction {
    margin-top: 30px;
}

.dashboard.reportManagement .reportAction .customSelect {
    margin-bottom: 0px;
    min-width: 250px;
    width: max-content;
}

.dashboard.reportManagement .reportAction .customInput {
    margin-bottom: 0;
}

.dashboard.reportManagement .reportAction .limitResults {
    width: 150px;
}

.dashboard.reportManagement .reportAction .reportLabel {
    min-width: 150px;
}

.dashboard.reportManagement .reportAction h6 {
    font-size: 16px;
    text-align: left;
    margin-bottom: 0;
}

.dashboard.reportManagement .reportAction .MuiListItem-button {
    padding: 0;
}
.dashboard.reportManagement .reportAction .MuiListItem-button label {
    width: 100%;
}

.dashboard.reportManagement .reportAction .MuiFormGroup-root {
    display: flex;
    justify-content: space-between;
}

.dashboard.reportManagement .reportAction .reportRange {
    border-left: 2px solid #ccc;
    padding-left: 20px;
    height: 80px;
}

.dashboard.reportManagement .reportAction .MuiInputBase-root.MuiInputBase-adornedEnd {
    height: 40px;
}

.dashboard.reportManagement .reportRange.datePicker .MuiFormControl-root.MuiTextField-root {
    margin-right: 30px;
}

.dashboard.reportManagement .orderResults .MuiFormGroup-root {
    flex-wrap: nowrap;
}

.dashboard.reportManagement .reportFields .customCheckbox {
    width: 25%;
}

/* AUCTION LOTS */

.dashboard.auctionLots .actionBox .abChild .customInput,
.dashboard.auctionLots .actionBox .abChild .MuiFormControl-root.MuiTextField-root {
    margin: 0;
    margin-right: 30px;
}

/* LISTINGS */

.dashboard.listing .actionBox .abChild .customInput,
.dashboard.listing .actionBox .abChild .MuiFormControl-root.MuiTextField-root {
    margin: 0;
    margin-right: 30px;
}

.inviteUsers .MuiFormControlLabel-root {
    margin: 0;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
}

.inviteUsers .MuiFormControlLabel-label {
    font-size: 18px;
    font-weight: 700;
    color: #212121;
}

/* USER MANAGEMENT */
.addUser {
    max-width: 1200px;
}

.addUserModal .addUser {
    margin: 0 auto;
}

.addUser .actionButton .secButton {
    margin-right: 20px;
}

.dashboard.userManagement .abChild .primButton,
.dashboard.employeeManagement .abChild .primButton {
    width: max-content;
    min-width: 200px;
    float: right;
}

.dashboard.employeeManagement .addEmp .primButton {
    width: max-content;
    min-width: 200px;
    float: left;
}

/* .dashboard .MuiTableCell-root.MuiTableCell-body:nth-child(n + 4) {
    text-align: right;
} */

.dashboard .MuiTableCell-root.MuiTableCell-body:first-child {
    text-align: left;
}
/* COMMUNICATIONS */

.dashboard.communications .commActBox .customInput {
    margin: 0;
    /* margin-right: 20px; */
}

.dashboard.communications .commActBox {
    margin: 10px 0 30px 0;
}

.dashboard.communications .commActBox .primButton {
    min-width: 150px;
}

.dashboard.communications table td:last-child,
.dashboard.communications table th:last-child {
    text-align: right;
}

/* INVOICE */

.dashboard.invoice .invChild .MuiFormControl-root.MuiTextField-root {
    width: 100%;
}

.dashboard.invoice .invChild .primButton {
    width: max-content;
    min-width: 180px;
    float: right;
}

/* INVOICE CART ITEM */

.cartItem {
    background: #fff;
    padding: 10px;
    margin-bottom: 10px;
}

.cartItem .itemInfo {
    width: 75%;
}

.cartItem .pclImg {
    width: 100%;
    max-width: 200px;
    position: relative;
    margin-right: 20px;
}

.cartItem .pclImg img {
    width: 100%;
    /* height: 150px; */
    object-fit: cover;
    border-radius: 3px;
}

.cartItem .listContent {
    width: max-content;
    text-align: left;
    margin-right: 20px;
}

.cartItem .listActBtn {
    width: 25%;
    max-width: max-content;
}

.cartItem .listContent .listProdTitle {
    font-size: 18px;
    font-weight: 600;
    color: #0e131f;
    margin-top: 5px;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.cartItem .listContent .listLotInfo {
    margin: 5px 0;
}

.cartItem .listContent .listLotInfo h5 {
    color: var(--secColor);
    font-size: 14px;
    margin: 0;
    line-height: 25px;
}

.cartItem .listContent .listLotInfo h5:nth-child(2) {
    padding-left: 20px;
    border-left: 1px solid #ccc;
    margin-left: 20px;
}

.cartItem .listContent .listLotInfo span:first-child {
    width: 90px;
    display: inline-block;
}

.cartItem .listContent .listLotInfo span:last-child {
    color: var(--secColor);
    font-size: 14px;
    font-weight: 700;
    padding-left: 5px;
    display: inline-block;
}

.cartItem .listContent .listLotInfo .gridDivider {
    padding: 0 10px;
}

.cartItem .listActBox {
    width: 30%;
}

.cartItem .listBidInfo p {
    font-size: 16px;
    color: var(--secColor);
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.cartItem .listBidInfo h6 {
    margin: 0;
    font-size: 14px;
    color: var(--secColor);
    padding-right: 10px;
}

.cartItem .listBidInfo p {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
}

.cartItem .listBidInfo p span:first-child {
    color: var(--primColor);
}

.cartItem .listBidInfo p span.listDivider {
    padding: 0 15px;
}

.cartItem .listBidInfo {
    margin-top: 5px;
}

.cartItem .listTimer p span {
    padding-right: 5px;
}

.cartItem .listBidBox .customInput {
    margin: 15px 0;
}

.cartItem button.removeCart {
    text-transform: initial;
    color: var(--primColor);
    margin-top: 5px;
    width: 100%;
}

/* INVOICE CART ITEM END */

/* SETTINGS */
.dashboard .generalSettings {
    max-width: 600px;
    width: 100%;
}

.dashboard .generalSettings .primButton {
    width: 150px;
}

.dashboard .generalSettings .settingsHead {
    text-align: left;
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 500;
}

.dashboard .generalSettings p {
    text-align: left;
    font-size: 16px;
    margin-bottom: 20px;
}

.btnOutlined {
    color: var(--primColor);
    border-color: var(--primColor);
}

.btnOutlined .material-icons {
    padding-right: 10px;
}

.wallet .fspBody .walletManageCredits {
    width: 50%;
}

.wallet .fspBody .walletManageCnt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(49%, 1fr));
    grid-gap: 15px;
}

.wallet .fspBody .walletManageCredits {
    border: 1px dashed #ccc;
    padding: 20px;
    width: 100%;
}

.wallet .fspBody .walletManageCnt h4 {
    font-size: 18px;
    font-weight: 700;
    color: #313131;
    margin-bottom: 15px;
}

.wallet .fspBody .walletManageCnt .primButton,
.wallet .fspBody .walletManageCnt .secButton {
    margin-top: 30px;
    width: max-content;
}

.wallet .fspBody .walletManageCredits .customTextArea {
    margin-bottom: 0;
}

.walletManageCredits .orderSummary > p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-item-align: center;
    align-self: center;
}

.walletManageCredits .orderSummary > p,
.walletManageCredits .orderSummary p label {
    font-size: 16px;
    color: #111111;
    font-weight: 600;
    margin-bottom: 0px;
}

.walletManageCredits .orderSummary p {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fspBody .fspTitle {
    font-size: 22px;
    font-weight: 700;
    color: var(--secColor);
    margin-bottom: 30px;
}

.fspBody .fspSubTitle {
    font-size: 18px;
    font-weight: 600;
    color: var(--secColor);
    margin-bottom: 20px;
}

@media (max-width: 1200px) {
    .dashboard.reportManagement .reportFields .customCheckbox {
        width: 33%;
    }
    .dashboard.reportManagement .reportAction .reportRange {
        padding-left: 0px;
        border-left: 0;
    }
}

@media (max-width: 845px) {
    .dashboard.reportManagement .reportFields .customCheckbox {
        width: 50%;
    }
}

@media (max-width: 768px) {
    .cartItem {
        flex-wrap: wrap;
        width: 48%;
    }
    .cartItem .itemInfo {
        width: 100%;
        flex-wrap: wrap;
    }

    .cartItem .pclImg {
        max-width: none;
        margin-right: 0;
    }

    .cartItem .pclImg img {
        height: 200px;
    }
    .cartItem .listContent {
        width: 100%;
        margin-right: 0;
    }
    .cartItem .listActBtn {
        width: 100%;
    }
    .dashboard.reportManagement .reportAction .reportLabel {
        width: 120px;
    }
    .dashboard.reportManagement .reportAction .RadioBox .MuiFormControlLabel-root {
        margin: 0;
    }
}

@media (max-width: 600px) {
    .dashboard.reportManagement .reportFields .customCheckbox {
        width: 100%;
    }
    .dashboard.reportManagement .reportAction {
        align-items: flex-start !important;
        margin-top: 15px;
    }
}

@media (max-width: 500px) {
    .cartItem .pclImg img {
        height: 150px;
    }
    .cartItem .itemInfo {
        width: 100%;
    }
    .cartItem {
        width: 100%;
    }

    .cartItem .listActBtn {
        display: flex;
        max-width: none;
        justify-content: space-between !important;
    }

    .cartItem .listActBtn {
        display: flex;
        max-width: none;
        justify-content: space-between !important;
    }

    .cartItem button.removeCart {
        width: max-content;
    }
    .dashboard.reportManagement .dashSubtitle {
        font-size: 15px;
        line-height: 24px;
        margin-top: 25px;
    }
    .dashboard .actionButton {
        margin: 25px 0 25px 0;
    }
    .dashboard .miscActions button {
        margin-bottom: 10px;
        width: 100%;
    }
    .dashboard.communications .commActBox {
        margin: 10px 0 15px 0;
    }
    .dashboard.communications .commActBox .customInput {
        margin-bottom: 15px;
    }
    .dashTitle {
        margin: 10px 0 25px 0;
    }
}
