.noRecordsFound {
    margin: 10px 0;
    padding: 15px;
    border: 1px dashed #dadada;
    color: #8e8e8e;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.noRecordsFound .material-icons {
    padding-right: 5px;
}
