.thumbsContainer,
.thumbsContainer .reorderCnt {
    display: flex;
    flex-wrap: wrap;
    margin: 15px 0;
}

.thumbsContainer .thumb {
    margin-right: 15px;
    margin-bottom: 15px;
}

.thumbsContainer .reorderCnt .thumb .thumbCnt {
    cursor: move;
    user-select: none;
}

.thumbsContainer .reorderCnt .thumb .thumbCnt:hover::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
}

.thumbsContainer .reorderCnt .thumb .thumbCnt:hover::after {
    font-family: 'Material Icons';
    position: absolute;
    content: '\e89f';
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 24px;
    color: #919191;
}

.thumbsContainer .reorderCnt .thumb .thumbCnt .fileActions {
    top: initial;
    bottom: 0;
    background: #262626d4;
    width: 100%;
    height: 35px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

/* .thumbsContainer .reorderCnt .fileActions .cancelBtn,
.thumbsContainer .reorderCnt .fileActions .viewBtn {
    color: #7d7d7d;
} */

.thumbsContainer .thumb img {
    height: 100px !important;
    width: 100px !important;
    border-radius: 5px;
    object-fit: cover;
    border: 1px solid #e3e3e3;
}

.thumbsContainer .thumb .thumbCnt {
    position: relative;
}

.thumbsContainer .thumb .thumbCnt {
    position: relative;
}

.thumbsContainer .thumb .thumbCnt .fileActions {
    position: absolute;
    top: 0px;
    right: 0px;
    border-radius: 5px;
    background: #5d5d5dc9;
    width: 100%;
    height: 100%;
    transition: 200ms all ease-in-out;
    visibility: hidden;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.thumbsContainer .thumb .thumbCnt:hover .fileActions {
    visibility: visible;
    opacity: 1;
}

.thumbsContainer .thumb .thumbCnt .cancelBtn {
    margin-right: 10px;
    cursor: pointer;
    color: #fff;
}

.thumbsContainer .thumb .thumbCnt .viewBtn {
    cursor: pointer;
    color: #fff;
}

/* 
.thumbsContainer .thumb .thumbCnt .cancelBtn {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #5d5d5d;
    cursor: pointer;
    transition: 200ms all ease-in-out;
} */

.thumbsContainer .thumb .thumbCnt .cancelBtn:hover {
    color: #ffa87a;
}

.thumbsContainer .thumb .thumbCnt .viewBtn:hover {
    color: #8dd7ff;
}

.notAvailable {
    color: #b7b7b7 !important;
}

.thumbsContainer .thumb .thumbCnt .defaultThumb {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
}

.thumbsContainer .thumb .thumbCnt .defaultThumb .material-icons {
    font-size: 60px;
    color: #ccc;
}

.dropzone {
    border: 1px dashed #c9c5d7;
    padding: 45px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #737373;
    flex-wrap: wrap;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 20px;
}

.dropzone h4 {
    width: 100%;
    font-size: 14px !important;
    font-weight: 700 !important;
    margin-bottom: 8px !important;
    margin-top: 8px;
}

.RadioBox .MuiFormControlLabel-root {
    margin-bottom: 0;
}

.customCheckbox .MuiFormHelperText-root,
.checkboxError .MuiFormHelperText-root,
.validationError {
    text-align: center;
    color: #f44336;
    font-size: 0.75rem;
}

.checkboxError {
    width: 100%;
}

.checkboxError p {
    text-align: left !important;
}

.cke {
    margin-bottom: 25px !important;
}
