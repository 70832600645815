:root {
    --fontFamily: 'Barlow'; /* To modify the font, add specific font path in index.html as well */
    --primColor: rgb(28, 28, 28);
    --secColor: rgb(116, 116, 116);
    --accentColor: #d32f2f;
    --backgroundColor: #f2f2f2;

    --sidePanelBg: #000;

    --sidePanelActiveLinkColor: #fff;
    --sidePanelActiveLinkBg: var(--accentColor);

    --sidePanelActiveSubLink: var(--accentColor);
}

.customContainer {
    padding-left: 60px !important;
    padding-right: 60px !important;
    width: 100% !important;
}

.primButton button,
.secButton button {
    height: 50px;
    border-radius: 3px;
}

.primButton.small button,
.secButton.small button {
    height: 40px;
}

.primButton.large button,
.secButton.large button {
    height: 60px;
}

::-moz-selection {
    /* Code for Firefox */
    color: #fff;
    background: rgb(25, 157, 229, 15);
}

::selection {
    color: #fff;
    background: rgb(25, 157, 229, 15);
}

a {
    color: var(--primColor);
}

@media (max-width: 991px) {
    .customContainer {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
}

@media (max-width: 600px) {
    .customContainer {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

@media (max-width: 500px) {
    .primButton.small button,
    .secButton.small button {
        height: 40px;
        font-size: 13px;
    }

    .primButton button,
    .secButton button {
        height: 45px;
        font-size: 14px;
    }

    .primButton.large button,
    .secButton.large button {
        height: 50px;
        font-size: 14px;
    }
}
