.login {
    background: var(--backgroundColor);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 100vh;
}

.login .loginCnt {
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    width: 100%;
    max-width: 500px;
    border-top: 5px solid var(--primColor);
}

.login .loginCnt .loginLogo {
    display: block;
    margin: 15px auto;
}

.login .loginCnt .loginLogo object,
.login .loginCnt .loginLogo img {
    width: 200px;
    object-fit: contain;
}

.login .loginCnt .loginTitle h2 {
    font-size: 18px;
    text-align: center;
    padding: 5px 0 15px 0;
}

.login .loginCnt .fpCnt .MuiFormControlLabel-root {
    margin-bottom: 0;
}

.login .loginCnt .fpCnt .MuiListItem-button {
    padding-left: 0;
}

.login .loginCnt .primButton {
    padding-top: 15px;
}

@media (max-width: 600px) {
    .login {
        background: #fff;
    }
    .login .loginCnt {
        max-width: initial;
        height: 100%;
        border-top: none;
    }
}

@media (max-width: 485px) {
    .login .loginCnt .fpCnt .MuiFormControlLabel-root {
        margin-bottom: 0;
        margin-right: 0;
    }
    .login .loginCnt .fpCnt .MuiFormControlLabel-root .MuiButtonBase-root {
        font-size: 15px;
    }

    .login .loginCnt .MuiFormGroup-row a {
        font-size: 15px;
    }
}
