.layout .MuiPaper-root.MuiDrawer-paper {
    position: relative;
    top: 0;
    left: 0;
    background: var(--sidePanelBg);
    height: 100vh;
    min-height: 100%;
    overflow-y: auto;
    color: #f3f3f3;
}

.layout .drawerMain {
    display: flex;
}

.layout .MuiButtonBase-root.MuiAccordionSummary-root,
.layout .MuiAccordionDetails-root {
    background: var(--sidePanelBg);
    color: #f3f3f3;
    width: 100%;
}

.layout .subLinks,
.layout .subLinks .MuiButtonBase-root.MuiListItem-button {
    width: 100%;
}

.layout .subLinks .MuiListItem-button a {
    padding: 5px 15px !important;
}

.layout .MuiAccordion-root.Mui-expanded {
    margin: 0;
}

.layout .MuiButtonBase-root.MuiAccordionSummary-expandIcon {
    color: #f3f3f3;
}

.layout .MuiAccordionSummary-root.active .MuiButtonBase-root.MuiAccordionSummary-expandIcon {
    color: var(--sidePanelBg);
}

.layout .MuiPaper-elevation1 {
    box-shadow: none;
}

.layout .MuiAccordion-root:before {
    content: none;
}

.layout .MuiPaper-root.MuiDrawer-paper::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.layout .MuiPaper-root.MuiDrawer-paper::-webkit-scrollbar-thumb {
    background: #a2a2a2;
    border-radius: 25px;
}
.layout .MuiPaper-root.MuiDrawer-paper::-webkit-scrollbar-track {
    background: #ebebeb;
}

.layout .MuiPaper-root.MuiDrawer-paper .MuiDivider-root {
    background-color: rgb(255 255 255 / 12%);
}

.layout .MuiPaper-root.MuiDrawer-paper .MuiListItem-button {
    padding: 0;
}

.layout .MuiPaper-root.MuiDrawer-paper button {
    color: #f3f3f3;
}

.layout .MuiPaper-root.MuiDrawer-paper .MuiListItem-button a {
    color: #f3f3f3;
    padding: 10px 15px;
    width: 100%;
}

.layout .MuiPaper-root.MuiDrawer-paper .MuiListItem-button a:hover {
    text-decoration: none;
}

.layout .MuiPaper-root.MuiDrawer-paper .MuiListItem-button a.active,
.layout .MuiButtonBase-root.MuiAccordionSummary-root.active {
    background: var(--sidePanelActiveLinkBg);
    color: var(--sidePanelActiveLinkColor);
    filter: saturate(0.8);
}

.layout .MuiAccordionSummary-root.Mui-expanded {
    min-height: 52px;
    height: 52px;
}

.layout .MuiPaper-root.MuiDrawer-paper .subLinks .MuiListItem-button a.active {
    background: var(--sidePanelBg);
    color: var(--sidePanelActiveSubLink);
    filter: saturate(1);
}

.layout .MuiPaper-root.MuiDrawer-paper .subLinks .MuiListItem-button a.active .MuiTypography-root {
    font-weight: 600;
}

.layout .MuiPaper-root.MuiDrawer-paper .MuiListItemText .MuiTypography-root {
    font-weight: 400;
}

.layout .layoutMain {
    width: calc(100% - 240px);
}

.layout .layoutMain main {
    position: relative;
    height: 100%;
}

.layout .layoutMain main .openDrawer {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    min-height: 100vh;
    background: var(--sidePanelBg);
}

.layout .layoutMain main .layoutBody {
    padding: 30px 60px 0 30px;
}

.layout .layoutMain main .layoutBody.reducePadding {
    padding-right: 30px;
}

.layout .layoutMain main .openDrawer button {
    margin: 0;
    color: #fff;
    padding-left: 15px;
}

.layout .layoutBody .layoutTitle {
    background: #eaeaea;
    padding: 10px 20px;
    border-radius: 3px;
}

.layout .layoutBody .layoutTitle h1 {
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
}

.layout .layoutBody .layoutTitle button span.material-icons {
    padding-right: 10px;
}

.layout .sliderBackdrop {
    visibility: hidden;
    opacity: 0;
    /* transition: 1000ms all ease-in-out; */
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #00000073;
    top: 0;
    left: 0;
    z-index: 10;
}

@media (max-width: 991px) {
    .layout .layoutMain main .layoutBody {
        padding: 30px 30px 0 30px;
    }
}

@media (max-width: 767px) {
    .drawerContent {
        padding: 0px 0 0 40px !important;
    }
}

@media (max-width: 600px) {
    .layout .layoutBody .layoutTitle h1 {
        font-size: 18px;
    }
    .dashboard.reportManagement .reportAction .customSelect {
        width: 100%;
    }
    .dashboard.reportManagement .reportAction .reportLabel {
        margin-top: 10px;
    }
    .layout .layoutMain {
        width: 100%;
    }
    .layout .layoutMain main {
        margin-left: 0;
    }
    /* .drawerMain .MuiDrawer-docked {
        position: fixed !important;
        height: 100vh;
        z-index: 10;
    } */
    .layout .MuiDrawer-root {
        position: absolute;
        top: 90px;
        width: 80%;
    }
    .layout .sliderBackdrop {
        visibility: visible;
        opacity: 1;
    }
    .layout .layoutBody .layoutTitle {
        flex-wrap: wrap;
    }
    .layout .MuiPaper-root.MuiDrawer-paper {
        position: fixed;
        width: inherit;
    }
    .drawerContent {
        padding: 0 !important;
    }
    .layout .layoutMain main .openDrawer {
        position: sticky;
        min-height: initial;
        height: 45px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 10px;
        top: 0;
        z-index: 10;
    }
    .layout .layoutMain main .layoutBody.reducePadding {
        padding: 15px;
    }
    .layout .layoutBody .layoutTitle {
        padding: 10px;
    }
    .layout .layoutBody .layoutTitle h1 {
        font-size: 15px;
    }
    .layout .layoutBody .layoutTitle button {
        font-size: 15px;
    }
}
