.productView .pvLt,
.productView .pvRt {
    width: 100%;
}

.productView .image-gallery-thumbnails-container {
    text-align: start;
}

.productView .image-gallery-icon {
    filter: none;
    background: #00000036;
    padding: 10px;
}

.productView .image-gallery-icon:hover {
    color: var(--primColor);
}

.productView .image-gallery-thumbnail {
    border: 2px solid transparent;
}

.productView .image-gallery-thumbnail.active,
.productView .image-gallery-thumbnail:hover,
.productView .image-gallery-thumbnail:focus {
    outline: none;
    border: 2px solid var(--primColor);
    border-radius: 5px;
}

.productView .pvTimerBox {
    border: 1px dashed #7ed2ff;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primColor);
    padding: 15px;
    border-radius: 5px;
    margin: 30px 0 15px 0;
}

.productView .pvTimerBox > * {
    margin-bottom: 0;
}

.productView .pvTimerBox label {
    padding-inline-start: 10px;
    padding-inline-end: 15px;
    font-size: 15px;
}

.productView .pvTimerBox h4 {
    font-size: 16px;
    font-weight: 700;
}

.productView .pvActBtn .pabChild {
    text-align: center;
}

.productView .pvActBtn {
    background: #f8f8f8;
    padding: 15px 10px;
}

.productView .pvBidBox {
    width: 100%;
    margin: 20px auto 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.productView .pvBidBox .biddingCnt > div:not(:last-child) {
    margin: 0;
    width: 49%;
}

.productView .pvBidBox .biddingCnt .customInput {
    margin-bottom: 5px;
}

.productView .pvBidBox .customInput,
.productView .customInput .MuiTextField-root,
.productView .customInput .MuiInputBase-root.MuiOutlinedInput-root,
.productView .customInput .MuiInputBase-root.MuiOutlinedInput-root input {
    height: 50px;
}

.productView .pvBidBox .customInput .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    margin-top: 5px;
}

.productView .pvBidBox .customInput .MuiInputLabel-outlined.MuiInputLabel-shrink {
    margin-top: 0px;
}

.productView .pvBidBox .tertButton {
    width: 100%;
    margin-inline-start: 10px;
}

.productView .pvBidBox .tertButton,
.productView .pvBidBox .tertButton button {
    height: 50px;
}

.productView .pvBidBox .primButton,
.productView .pvBidBox .secButton,
.productView .pvBidBox .primButton button,
.productView .pvBidBox .secButton button {
    height: 50px;
    font-weight: 700;
}

.productView .pvBidBox form {
    width: 80%;
    margin: 0 auto;
}

.productView .pvBidBox form:not(:nth-child(1)) {
    margin-top: 30px;
}

.productView .pvActBtn .pabChild label {
    font-size: 14px;
    color: #373737;
}

.productView .pvActBtn .pabChild h4 {
    font-size: 18px;
    color: #373737;
    font-weight: 700;
}

.productView .pvActBtn .pabChild.pvCurrentBid h4 {
    color: var(--primColor);
}

.productView .pvBidHistBtn {
    color: var(--primColor);
    text-transform: initial;
    margin: 10px auto;
    font-weight: 600;
    display: flex;
}
.productView .pvBidHistBtn .MuiButton-label .material-icons {
    padding-inline-end: 10px;
}

.productView .pvBidBox > div {
    margin: 0;
    width: 49%;
    margin-inline-end: 10px;
}

.productView .pvBidBox .primButton,
.productView .pvBidBox .secButton {
    width: 100%;
    margin: 0 auto;
}

.productView .pvProductInfo h2 {
    font-size: 24px;
    color: #0e131f;
    margin: 0;
    font-weight: 700;
}

.productView .pvProductDesc label {
    font-size: 15px;
    color: #373737;
    font-weight: 600;
    margin: 0;
    padding-inline-end: 10px;
}

.productView .pvProductDesc h6 {
    font-size: 15px;
    color: #646464;
    font-weight: 400;
    margin: 0;
    display: inline;
}

.productView .pvProductDesc .pvDescChild {
    margin-bottom: 10px;
}

.productView .cartHead {
    font-size: 24px;
    color: #353535;
    font-weight: 700;
    margin: 30px 0 10px 0;
    text-transform: uppercase;
}

.productView .MuiPaper-root.MuiAccordion-root {
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.productView .MuiButtonBase-root.MuiAccordionSummary-root {
    margin-bottom: 0;
}

.productView .MuiAccordionDetails-root {
    font-size: 15px;
}

.productView .accTitle {
    margin-bottom: 0;
    font-weight: 600;
}

.productView .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 80px;
    object-fit: cover;
    border-radius: 5px;
}

.productView .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 500px;
}

.productView .image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
    height: auto;
}

.productView .pvBidStatus {
    padding: 10px 0;
    background: #fff;
    position: sticky;
    width: 100%;
    top: -20px;
    z-index: 10;
}

.productView .pvBidStatus h4 {
    padding: 15px;
    color: #fff;
    height: 45px;
    width: 100%;
    margin: 0;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1.25px;
    border-radius: 5px;
}

.productView .pvBidStatus h4.winning {
    background: #06a2b4;
}

.productView .pvBidStatus h4.won {
    background: #06b473;
}

.productView .pvBidStatus h4.outbid {
    background: #ff9472;
}

.productView .pvBidStatus h4.lost {
    background: #ff7272;
}

.rightDrawer .MuiPaper-root.MuiDrawer-paper {
    width: 50vmax;
    max-width: 800px;
    padding: 20px;
}

@media (max-width: 1024px) {
    .productView .pvBidBox form {
        width: 100%;
    }
}

@media (max-width: 500px) {
    .productView .pvBidBox {
        width: 100%;
    }
    .productView .cartHead {
        font-size: 18px;
        color: #353535;
        font-weight: 700;
        margin: 20px 0 10px 0;
        text-transform: uppercase;
    }
    .productView .pvBidBox form:nth-child(2) {
        margin-top: 15px;
    }
}

@media (max-width: 420px) {
    .productView .pvBidBox .biddingCnt {
        flex-wrap: wrap;
    }
    .productView .pvBidBox .biddingCnt .customInput {
        /* width: 100%; */
        margin-bottom: 15px;
    }
    /* .productView .pvBidBox .biddingCnt .primButton,
    .productView .pvBidBox .biddingCnt .secButton {
        width: 100%;
    } */
    .similarItem .pclImg img {
        height: 90px;
    }
    .productView .pvBidStatus h4 {
        font-size: 13px;
    }
}
