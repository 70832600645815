.languageManager .langTabs {
    margin-top: 30px;
}

.languageManager .langTabs .langHead {
    background: #0a40732b;
    padding: 12px;
    margin-bottom: 30px;
}

.languageManager .langTabs .langHead h3 {
    margin: 0;
    text-align: left;
    font-size: 16px;
    font-weight: 700;
    color: var(--secColor);
}

.languageManager .langTabs .MuiBox-root {
    padding-top: 30px;
}

.languageManager .langTabs .langInstructions {
    margin: 0;
    text-align: left;
}

.languageManager .langTabs .langInstructions {
    margin: 0;
    list-style: none;
    position: relative;
}

.languageManager .langTabs .langInstructions li {
    counter-increment: step-counter;
    margin-top: 20px;
    line-height: 30px;
    padding-left: 25px;
    color: var(--secColor);
    font-size: 16px;
}

.languageManager .langTabs .langInstructions li::before {
    position: absolute;
    content: counter(step-counter);
    font-size: 14px;
    color: #ffffff;
    font-weight: 600;
    left: 15px;
    background: var(--primColor);
    border-radius: 100%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.languageManager .langTabs .langPhrase {
    text-align: left;
    max-width: 600px;
    width: 100%;
}

.languageManager .langTabs .langPhrase h5 {
    font-size: 15px;
    margin-bottom: 20px;
}

.languageManager .langTabs .langPhrase .primButton,
.languageManager .langTabs .langPhrase .secButton {
    width: max-content;
}

.langPhrase .phraseSelected {
    width: 100%;
    max-width: 500px;
    margin-bottom: 10px;
}

.langPhrase .phraseSelected label,
.langPhrase .phraseSelected h6 {
    margin: 0;
}

.langPhrase .phraseSelected label {
    font-weight: 600;
    padding-right: 20px;
}

.langPhrase .phraseSelected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.langPhrase .phraseSelected .psInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.langPhrase .phraseSelected .vertDivider {
    width: 2px;
    height: 20px;
    background: #ccc;
}

.languageManager .langTabs .csvActBtn .secButton:first-child {
    margin-right: 10px;
}

.MuiPaper-root .langHead h3 {
    font-size: 20px;
    font-weight: 700;
    color: var(--secColor);
    margin-bottom: 10px;
}

.MuiPaper-root .langPhrase h5 {
    font-size: 18px;
    color: #313131;
    margin-bottom: 30px;
}
