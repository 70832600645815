.liveAuction {
    margin: 30px auto;
}

.liveAuction .liveAucImages .image-gallery {
    max-height: 100%;
}

.liveAuction .liveAucImages .image-gallery-content.left .image-gallery-slide .image-gallery-image,
.liveAuction .liveAucImages .image-gallery-content.right .image-gallery-slide .image-gallery-image {
    height: 400px;
    width: 100%;
    object-fit: contain;
    border-radius: 5px;
    border: 1px solid #e6e6e6;
}

.liveAuction
    .liveAucImages
    .image-gallery-content.left.fullscreen
    .image-gallery-slide
    .image-gallery-image {
    max-height: -webkit-fill-available;
    height: 100%;
}

.liveAuction .liveAucImages .image-gallery-content.left.fullscreen .image-gallery-slides {
    background: initial;
}

/* .liveAuction .liveAucImages .image-gallery-icon {
    width: 30px;
    height: 30px;
    color: #111;
    padding: 0;
    filter: none;
} */

.liveAuction .liveAucImages .image-gallery-left-nav .image-gallery-svg,
.liveAuction .liveAucImages .image-gallery-right-nav .image-gallery-svg {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: #ebebeb;
    padding: 5px;
}

.liveAuction .liveAucImages .image-gallery-left-nav {
    left: 10px;
}

.liveAuction .liveAucImages .image-gallery-right-nav {
    right: 10px;
}

.liveAuction .image-gallery-thumbnails {
    margin-top: 15px;
}

.liveAuction .liveAucImages .image-gallery-thumbnail .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 75px;
    line-height: 0;
    height: 52px;
    border-radius: 5px;
    object-fit: cover;
    margin-bottom: 5px;
}

.liveAuction .liveAucImages .image-gallery-thumbnail .image-gallery-thumbnail-image {
    border: 2px solid transparent;
}

.liveAuction .liveAucImages .image-gallery-thumbnail,
.liveAuction .liveAucImages .image-gallery-thumbnail.active,
.liveAuction .liveAucImages .image-gallery-thumbnail:hover,
.liveAuction .liveAucImages .image-gallery-thumbnail:focus {
    width: 75px;
    border: none;
}

.liveAuction .liveAucImages .image-gallery-thumbnail:hover .image-gallery-thumbnail-image,
.liveAuction .liveAucImages .image-gallery-thumbnail:focus .image-gallery-thumbnail-image {
    border: 2px solid var(--primColor);
}

.liveAuction .liveAucImages .image-gallery-thumbnails-wrapper.left,
.liveAuction .liveAucImages .image-gallery-thumbnails-wrapper.right {
    width: 75px;
}

.liveAuction .liveAucImages .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 400px;
    object-fit: cover;
    width: 100%;
    border-radius: 5px;
}

.liveAuction .image-gallery-slide-wrapper.left,
.liveAuction .image-gallery-slide-wrapper.right {
    height: 400px;
}

.liveAuction .liveAucImages .image-gallery-thumbnails .image-gallery-thumbnails-container {
    text-align: start;
    margin-bottom: 25px;
}

.liveAuction .pvTimerCnt {
    position: sticky;
    top: 0;
    z-index: 10;
    background: #fff;
    padding: 10px 0;
    margin-bottom: 30px;
}

.liveAuction .pvTimerBox {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primColor);
    padding: 15px;
    border-radius: 5px;
    width: 100%;
    background: #e4f5ff;
}

.liveAuction .pvTimerBox > * {
    margin-bottom: 0;
}

.liveAuction .pvTimerBox label {
    padding-inline-start: 10px;
    padding-inline-end: 15px;
    font-size: 15px;
}

.liveAuction .pvTimerBox h4 {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.liveAuction .pvTimerBox .material-icons {
    padding-inline-end: 10px;
}

.videoAuction {
    background: #f3f3f3;
    border-radius: 5px;
    padding: 15px;
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
}

.videoAuction h5 {
    font-size: 16px;
    color: #373737;
    font-weight: 600;
    width: 100%;
}

.liveAuction .laInfo {
    padding: 0;
    margin: 0;
    text-align: start;
    list-style-type: none;
    margin-top: 45px;
}

.liveAuction .laInfo li {
    margin-bottom: 15px;
}

.liveAuction .laInfo li > label {
    font-size: 15px;
    font-weight: 600;
    color: #373737;
    padding-inline-end: 15px;
    margin-bottom: 0;
}

.liveAuction .laInfo li label > span {
    font-size: 15px;
    color: #646464;
}

.liveAuction .laInfo li.descriptionInfo span {
    display: block;
    width: 100%;
    line-height: 22px;
}

.liveAuction .pvAccordian .MuiButtonBase-root {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.liveAuction .pvAccordian .MuiCollapse-container {
    text-align: start;
}

.liveAuction .laTitle {
    font-size: 20px;
    font-weight: 700;
    text-align: start;
    text-transform: uppercase;
    margin: 25px 0 10px 0;
    color: var(--secColor);
}

.liveAuction .productView {
    font-size: 24px;
    color: #0e131f;
    margin: 0 0 20px 0;
    font-weight: 700;
    text-align: start;
}

.productViewInvite .productView .pvLt {
    width: 40%;
}
.productViewInvite .productView .pvRt {
    width: 60%;
}

.liveAuction .searchResults.Grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;
}

.liveAuction .gridListToggle .MuiButtonBase-root.MuiButton-root {
    min-width: max-content;
    color: #8e8e8e;
}

.liveAuction .gridListToggle .MuiButtonBase-root.MuiButton-root.active {
    color: var(--primColor);
}

.liveAuction .MuiAutocomplete-root .MuiInputBase-root.MuiOutlinedInput-root {
    height: 40px;
    margin-bottom: 15px;
}

/* .liveAuction
    .MuiAutocomplete-root
    .MuiInputBase-root.MuiOutlinedInput-root
    .MuiInputLabel-formControl {
    top: 8px;
} */

@media (max-width: 1024px) {
    .liveAuction {
        margin: 10px auto;
    }
    .liveAuction .pvTimerCnt {
        top: 90px;
    }
    .videoAuction {
        height: 100%;
        min-height: 400px;
    }
}

@media (max-width: 991px) {
    .auctionAssetsCnt {
        flex-direction: column-reverse;
    }
    .auctionAssetsCnt .liveAucImages {
        margin-top: 30px;
    }
}

@media (max-width: 500px) {
    .liveAuction .pvTimerCnt {
        top: 70px;
        margin-bottom: 10px;
    }
    .liveAuction .pvTimerBox h4 {
        font-size: 16px;
    }
    .videoAuction {
        min-height: 300px;
    }
    .liveAuction .liveAucImages .image-gallery-content .image-gallery-slide .image-gallery-image {
        height: 300px;
    }
    .image-gallery-fullscreen-button .image-gallery-svg {
        height: 24px;
        width: 24px;
    }
}

@media (max-width: 445px) {
    .liveAuction .pvTimerBox h4 {
        letter-spacing: 0;
    }
    .liveAuction .pvTimerBox {
        padding: 15px 5px;
    }
}
